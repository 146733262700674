import { Disclosure } from '@headlessui/react';
import {
  ChevronRightIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import { HydraLogo } from '~/assets/HydraLogo';
import { Routes, withParams } from '~/constants/routes';
import { UserSession } from '~/services/auth/Schemas';
import { Button } from '../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { cn } from '../ui/utils';
import { NavigationItem } from './types';
import { MailIcon } from 'lucide-react';
import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';
import { ValidatedForm } from 'remix-validated-form';
import { Input } from '../ui/input';

type OrganizationMembershipInvitation = {
  id: string;
  organization: {
    name: string;
  };
  role: 'Member' | 'Admin' | 'Owner';
  inviter: {
    firstName: string;
    lastName: string;
  };
};

export function DesktopNavigationBar({
  navigation,
  pathname,
  user,
  className,
  organizationMembershipInvitations,
}: {
  navigation: NavigationItem[];
  pathname: string;
  user?: UserSession;
  className?: string;
  organizationMembershipInvitations?: OrganizationMembershipInvitation[];
}) {
  return (
    <div
      className={cn(
        'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col',
        className,
      )}
    >
      <div className="flex flex-col px-6 pb-4 overflow-y-auto border-r grow gap-y-5 bg-background">
        <div className="flex items-center justify-center h-auto shrink-0">
          <HydraLogo className="h-[5.5rem] w-auto" />
        </div>

        <nav className="flex flex-col flex-1">
          <ul className="flex flex-col flex-1 gap-y-7">
            <ul className="-mx-2 space-y-1">
              {navigation.map((item, index) => {
                return (
                  <li key={`${item.name}-${index}`}>
                    {!item.children ? (
                      <Link
                        to={item.to!}
                        className={cn(
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-muted',
                          pathname.includes(item.to!) &&
                            'bg-primary text-white',
                        )}
                      >
                        <item.icon
                          className="w-6 h-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure
                        as="div"
                        key={`${item.name}-${index}`}
                        defaultOpen={item.children.some((child) =>
                          pathname.includes(child.to),
                        )}
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={cn(
                                'flex w-full items-center gap-x-3 rounded-md bg-background p-2 text-left text-sm font-semibold leading-6 text-muted',
                                item.children!.some((child) =>
                                  pathname.includes(child.to),
                                ) && 'bg-primary text-white',
                              )}
                            >
                              <item.icon
                                className="w-6 h-6 shrink-0"
                                aria-hidden="true"
                              />

                              {item.name}
                              <ChevronRightIcon
                                className={cn(
                                  open ? 'rotate-90' : '',
                                  'ml-auto h-5 w-5 shrink-0',
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul" className="px-2 mt-1">
                              {item.children?.map((subItem) => (
                                <li key={`${subItem.name}-${index}`}>
                                  <Link
                                    className={cn(
                                      'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-muted hover:text-foreground',
                                      pathname.includes(subItem.to) &&
                                        'font-bold text-white',
                                    )}
                                    to={subItem.to}
                                  >
                                    {subItem.name}
                                  </Link>
                                </li>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                  </li>
                );
              })}
            </ul>
            {user && user.organizationMemberships && (
              <>
                <li>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-xs font-semibold leading-6 text-indigo-200">
                      Your organizations
                    </p>

                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Link to={Routes.OrganizationsCreate}>
                            <PlusCircleIcon className="w-4 h-4 text-indigo-200 hover:text-primary" />
                          </Link>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Create a new organization</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>

                  <ul className="mt-2 -mx-2 space-y-1">
                    {user.organizationMemberships.map(
                      (organizationMembership, index) => (
                        <li
                          key={`${organizationMembership.organization.name}-${index}`}
                        >
                          <Link
                            to={withParams(Routes.OrganizationsMembers, {
                              organizationId:
                                organizationMembership.organization.id,
                            })}
                            className="flex justify-between p-2 text-sm font-semibold leading-6 rounded-md group gap-x-3"
                          >
                            <div className="flex gap-3">
                              <span
                                className={cn(
                                  'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-primary bg-primary text-[0.625rem] font-medium text-muted',
                                  pathname.includes(
                                    organizationMembership.organization.id,
                                  ) && 'text-white',
                                )}
                              >
                                {organizationMembership.organization.name[0]}
                              </span>
                              <span
                                className={cn(
                                  'truncate text-muted',
                                  pathname.includes(
                                    organizationMembership.organization.id,
                                  ) && 'text-white',
                                )}
                              >
                                {organizationMembership.organization.name}
                              </span>
                            </div>
                          </Link>
                        </li>
                      ),
                    )}
                  </ul>
                </li>

                {organizationMembershipInvitations?.map((invitation) => (
                  <div
                    key={invitation.id}
                    className="px-4 py-3 space-y-2 border rounded-lg border-muted-foreground"
                  >
                    <p className="flex items-center gap-2 text-sm font-bold">
                      <MailIcon className="w-4 h-4" />
                      {invitation.organization.name}
                    </p>

                    <p className="text-xs">
                      <span className="font-semibold">
                        {invitation.inviter.firstName}{' '}
                        {invitation.inviter.lastName}
                      </span>{' '}
                      has invited you to join their organization as{' '}
                      {invitation.role === 'Member' ? 'a' : 'an'}{' '}
                      <span className="font-semibold">{invitation.role}</span>.
                    </p>

                    <ValidatedForm
                      action="/onboarding/organization-membership"
                      className="flex justify-end pt-2"
                      method="PATCH"
                      validator={modifyInvitationValidator}
                    >
                      <Input
                        name="invitationId"
                        className="hidden"
                        defaultValue={invitation.id}
                      />

                      <div className="flex gap-2">
                        <Button
                          className="p-2 py-2 text-xs"
                          variant="outline"
                          size="sm"
                          name="action"
                          value="decline"
                          type="submit"
                        >
                          Decline
                        </Button>

                        <Button
                          className="p-2 py-2 text-xs"
                          size="sm"
                          name="action"
                          value="accept"
                          type="submit"
                        >
                          Accept
                        </Button>
                      </div>
                    </ValidatedForm>
                  </div>
                ))}
              </>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

const modifyInvitationValidator = withZod(
  z.object({
    invitationId: z.string(),
    action: z.enum(['accept', 'decline']),
  }),
);
